<template>
  <div class="focus-page">
    <div class="focus-left" v-loading="loading">
      <totalCom
        style="margin-bottom:10px"
        :text="'重点关注数据共' + total + '条'"
      />
      <div
        class="focus-left-content"
        v-infinite-scroll="getRemarkPage"
        :infinite-scroll-disabled="scollDisabled"
        :infinite-scroll-distance="20"
      >
        <div class="content-item" v-for="(item, i) in list" :key="i">
          <div class="content-item-title">
            <span class="content-title" @click="lookNews(item.article)">
              {{ item.article.title }}
            </span>
            <div class="item-info">
              <el-popover
                placement="bottom"
                trigger="hover"
                popper-class="focus-popover"
              >
                <div>
                  <div>
                    <img :src="require('@/assets/imgs/i8.png')" alt="" />
                    <span class="btn-1" @click="lookNews(item.article)">
                      查看新闻
                    </span>
                  </div>

                  <div style="margin-top:5px">
                    <img :src="require('@/assets/imgs/i9.png')" alt="" />
                    <span
                      class="btn-1"
                      @click="clearRemarkItem(item.article.id)"
                    >
                      取消关注
                    </span>
                  </div>
                </div>
                <span slot="reference" class="head-info">
                  <img :src="require('@/assets/imgs/i7.png')" alt="" />
                </span>
              </el-popover>
            </div>
          </div>
          <div class="content-item-time">
            {{ item.article.issuingTime }} {{ item.article.author }}
          </div>
          <div class="content-item-text" v-html="item.article.text"></div>
          <div class="content-item-bottom">
            <img v-if="item.article.ico" :src="item.article.ico" alt="" />
            <span class="item-bottom-span1">
              {{ item.article.websiteName }}
            </span>
            <span class="item-bottom-span2">
              来源：
              <a :href="item.article.url" target="_blank">
                {{ item.article.url }}
              </a>
            </span>
          </div>
        </div>
        <li v-if="nomoreData && list.length > 0">加载中...</li>
        <li v-else-if="!nomoreData && list.length > 0">没有更多了...</li>
      </div>
      <!-- <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        background
        :sizesArr="[15, 30, 60, 200]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      /> -->
    </div>
    <div class="focus-right">
      <div class="focus-right-title">近8条查看记录</div>
      <div class="focus-right-title-border"></div>
      <div class="focus-right-record">
        <div class="right-item" v-for="(item, i) in listRetrieval" :key="i">
          <div class="right-item-left">
            {{ i + 1 }}
          </div>
          <div class="right-item-right">
            <div class="right-item-right-text">
              <span @click="lookLogNews(item)">{{ item.title }}</span>
            </div>
            <div class="right-item-bottom">{{ item.websiteName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { remarkPage, clearArticleRemark, readSearch } from '@/api/sentiment.js'
export default {
  data() {
    return {
      loading: false,
      list: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 30,
      // 总记录数
      total: 0,
      listRetrieval: [],
      scollDisabled: false,
      nomoreData: true
    }
  },
  created() {
    // this.getRemarkPage()
    this.getlogSearch()
  },
  methods: {
    getRemarkPage() {
      this.loading = true
      this.scollDisabled = true
      remarkPage(this.current, this.limit).then(res => {
        // console.log(res)
        let data = res.items.map(item => {
          if (!item.article) {
            item.article = {}
          }
          return item
        })
        if (data.length > 0) {
          this.current++
          this.scollDisabled = false
          this.list = this.list.concat(data)
          if (data.length < this.limit) {
            this.nomoreData = false
          }
        } else {
          this.nomoreData = false
        }
        this.total = res.all
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getRemarkPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getRemarkPage()
    },
    // 取消关注
    clearRemarkItem(id) {
      clearArticleRemark(id).then(res => {
        this.$message({
          type: 'success',
          message: '取消关注!'
        })
        this.current = 1
        this.list = []
        this.getRemarkPage()
      })
    },
    // 查看新闻
    lookNews(row) {
      this.$router.push({
        path: '/sentiment/detail/' + row.id,
        query: {
          newsTitle: row.title
        }
      })
    },
    // 记录查看新闻
    lookLogNews(row) {
      this.$router.push({
        path: '/sentiment/detail/' + row.articleId,
        query: {
          newsTitle: row.title
        }
      })
    },
    // 查看记录
    getlogSearch() {
      readSearch().then(res => {
        this.listRetrieval = res
      })
    }
  }
}
</script>

<style lang="scss">
.focus-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;

  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .focus-left {
    width: calc(100% - 410px);
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 14px;
    .focus-left-content {
      width: 100%;
      height: calc(100% - 40px);
      overflow: hidden;
      overflow-y: auto;
      .content-item {
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 15px;
        margin-bottom: 20px;
        .content-item-title {
          font-size: 18px;
          font-weight: bold;
          color: #1a1a1b;
          position: relative;
          .content-title {
            cursor: pointer;
            &:hover {
              color: #0582e7;
            }
          }
          .item-info {
            position: absolute;
            right: 20px;
            top: 0px;
            img {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }
          }
        }
        .content-item-time {
          font-size: 12px;
          font-weight: 300;
          color: #666666;
          margin: 15px 0;
        }
        .content-item-text {
          font-size: 14px;
          font-weight: 300;
          color: #444444;
          margin: 15px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 26px;
        }
        .content-item-bottom {
          img {
            width: auto;
            height: 30px;
            object-fit: cover;
            vertical-align: middle;
            border-radius: 5px;
            margin-right: 10px;
          }
          .item-bottom-span1 {
            font-size: 14px;
            margin-right: 15px;
            font-weight: 300;
            color: #666666;
          }
          .item-bottom-span2 {
            font-size: 12px;
            font-weight: 300;
            color: #1a1a1b;
          }
        }
      }
      li {
        text-align: center;
        font-size: 12px;
        color: #9c9797;
        list-style: none;
      }
    }
  }
  .focus-right {
    width: 400px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 14px;
    .focus-right-title {
      padding-left: 10px;
      border-left: 4px solid #694638;
      font-size: 14px;
      font-weight: 300;
      color: #2a2a2a;
    }
    .focus-right-title-border {
      border-bottom: 1px solid #e5e5e5ce;
      margin: 13px 0;
    }
    .focus-right-record {
      height: calc(100% - 40px);
      overflow: hidden;
      overflow-y: auto;
      .right-item {
        display: flex;
        margin: 12px 0;
        border-bottom: 1px solid #dad8d8;
        &:nth-last-child(1) {
          border-bottom: 0;
        }
        .right-item-left {
          font-size: 26px;
          font-weight: 400;
          color: #694638;
        }
        .right-item-right {
          margin-left: 10px;
        }
        .right-item-right-text {
          font-size: 14px;
          font-weight: bold;
          color: #131313;
          line-height: 24px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          span {
            cursor: pointer;
            &:hover {
              color: #1188f8;
            }
          }
        }
        .right-item-bottom {
          font-size: 12px;
          padding: 15px 0;
          font-weight: 400;
          color: #6d6c6b;
        }
      }
    }
  }
}
.focus-popover {
  width: 120px !important;
  min-width: 50px !important;
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    vertical-align: middle;
  }
  .btn-1 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    color: #000000;
    cursor: pointer;
    &:hover {
      color: rgb(0, 140, 255);
    }
  }
}
</style>
