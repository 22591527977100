import request from '@/plugins/axios'

export function articlePage(page, limit, data) {
  return request({
    url: `/article/${page}/${limit}`,
    method: 'post',
    data
  })
}

export function articleDetail(id) {
  return request({
    url: `/article/${id}`,
    method: 'get'
  })
}

// 关注接口
export function articleRemark(id) {
  return request({
    url: `/remark/${id}`,
    method: 'post'
  })
}

export function clearArticleRemark(id) {
  return request({
    url: `/remark/delByArticleId/${id}`,
    method: 'delete'
  })
}

export function remarkPage(page, limit) {
  return request({
    url: `/remark/${page}/${limit}`,
    method: 'get'
  })
}

// 日志

export function logSearch() {
  return request({
    url: `/log/search`,
    method: 'get'
  })
}

export function readSearch() {
  return request({
    url: `/log/read`,
    method: 'get'
  })
}

export function deleteSearchLog(id) {
  return request({
    url: `/log/search/${id}`,
    method: 'delete'
  })
}
